<template>
  <div>
    <div
      class="event"
      style="margin-bottom: 200px"
    >
      <img
        src="http://crenor.speedgabia.com/images/review_page.jpg"
        alt="메디털트렌드 리뷰 이벤트"
      >

      <div class="counter">
        <div
          class="icon"
          style="top:0;"
        >
          <div style="top: 400px; left: 247px;">
            <span>{{ eventHistory.filter(e => e.condition_1 === 'heart').length }}명</span>
          </div>

          <img
            v-show="selectType === 'heart'"
            class="clickBtn"
            src="http://crenor.speedgabia.com/images/heart.jpg"
            style="position: absolute; top: 119px; left: 210px;"
          >

          <div style="top: 400px; left: 536px;">
            <span>{{ eventHistory.filter(e => e.condition_1 === 'like').length }}명</span>
          </div>

          <img
            v-show="selectType === 'like'"
            class="clickBtn"
            src="http://crenor.speedgabia.com/images/like.jpg"
            style="position: absolute; top: 105px; left: 499px;"
          >

          <div style="left: 812px; top: 400px;">
            <span>{{ eventHistory.filter(e => e.condition_1 === 'star').length }}명</span>
          </div>

          <img
            v-show="selectType === 'star'"
            class="clickBtn"
            src="http://crenor.speedgabia.com/images/star.jpg"
            style="position: absolute; top: 109px; left: 775px;"
          >
        </div>

        <img
          src="http://crenor.speedgabia.com/images/review_01.jpg"
          usemap="#medical-review-map"
          alt="메디털트렌드 리뷰 이벤트 참여하기"
        >

        <map name="medical-review-map">
          <area
            class="clickBtn"
            alt="추천해요"
            title="추천해요"
            coords="242,158,111"
            shape="circle"
            @click="completeEvent === false ? selectType = 'heart' : selectType"
          >

          <area
            class="clickBtn"
            alt="유익해요"
            title="유익해요"
            coords="547,159,113"
            shape="circle"
            @click="completeEvent === false ? selectType = 'like' : selectType"
          >

          <area
            class="clickBtn"
            alt="기대돼요"
            title="기대돼요"
            coords="857,159,114"
            shape="circle"
            @click="completeEvent === false ? selectType = 'star' : selectType"
          >
        </map>

        <img
          v-if="completeEvent"
          src="http://crenor.speedgabia.com/images/completed_btn.png"
        >

        <button
          v-else-if="userName === null"
          class="submit"
          style="bottom: -184px;"
        >
          <img
            src="http://crenor.speedgabia.com/images/login_btn.png"
            @click="redirectLogin"
          >
        </button>

        <button
          v-else
          class="submit"
        >
          <img
            src="http://crenor.speedgabia.com/images/submit_btn.png"
            @click="createEventHistory"
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { changeUserPoint } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      completeEvent: false,
      selectType: null,
      eventHistory: [],
      isProcessing: false,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
    }),
  },
  watch: {
    selectType() {
      // console.log(this.selectType)
    }
  },
  created() {
    this.fetchEventHistory()

    if (this.userName) this.fetchEventComplete()
  },
  methods: {
    fetchEventHistory() {
      axios.get(`/fu/event/history/${this.eventId}/${this.contentId}`)
        .then(rs => {
          this.eventHistory = rs.data
        })
        .catch(() => {
        })
    },
    fetchEventComplete() {
      axios.get(`/fu/event/complete/${this.eventId}/${this.contentId}`)
        .then(rs => {
          if (rs.data) {
            this.selectType = rs.data.condition_1
            this.completeEvent = true
          } else this.completeEvent = false
        })
        .catch(() => {
        })
    },
    createEventHistory() {
      if (this.userName) {
        if (this.selectType === null) this.$parent.showAlertDialog('리뷰를 선택해주세요.')
        else if (this.isProcessing === false) {
          this.isProcessing = true

          axios.post('/fu/event/history', {
            EventId: this.eventId,
            ContentId: this.contentId,
            Condition_1: this.selectType
          })
            .then(rs => {
              this.$parent.showAlertDialog('이벤트 참여 하였습니다.', () => {
                this.completeEvent = true

                setStatistics(121, this.eventId)

                changeUserPoint(rs.data)

                this.fetchEventHistory()

                this.isProcessing = false
              })
            })
            .catch(() => {
              this.$parent.showAlertDialog('이벤트 참여 중 오류가 발생했습니다.')
            })
        }
      } else {
        this.redirectLogin()
      }
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-medical-trend-main' }, params: { id: this.contentId } }).catch(() => {})
    },
  }
}
</script>

<style scoped>
img {
  display: block;
  margin: 0 auto;
}

.counter {
  position: relative;
  width: 1096px;
  margin: 0 auto;
}

.counter div {
  position: absolute;
  top: 386px;
}

.counter span {
  font-size: 22px;
  width: 80px;
  display: block;
}

area:hover {
  cursor: pointer;
}

button.submit {
  background: none;
  border: none;
  position: absolute;
  left: 245px;
  bottom: -114px;
}
</style>
